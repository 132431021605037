import React, { useEffect } from 'react';



const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('breathing-animation');
        return;
      }
    });
});

const AddAnimation = () => {
    useEffect(() => {
        const headlines = document.querySelectorAll('.left-headline');
        const pictureTextBoxes = document.querySelectorAll('.small-headline');
        const roundPictures = document.querySelectorAll('.round-picture');
        const smallHeadlines = document.querySelectorAll('.small-headline');
        const normalTexts = document.querySelectorAll('.normal-text');
        const dotses = document.querySelectorAll('.dots3');
        const dotsFrames = document.querySelectorAll('.dots-frame');
        const introPictures = document.querySelectorAll('.intro-picture');
        const topComponents = document.querySelectorAll('.top-components');

        // Assuming 'observer' is defined outside this component and is accessible here
        headlines.forEach((element) => observer.observe(element));
        pictureTextBoxes.forEach((element) => observer.observe(element));
        roundPictures.forEach((element) => observer.observe(element));
        smallHeadlines.forEach((element) => observer.observe(element));
        normalTexts.forEach((element) => observer.observe(element));
        dotses.forEach((element) => observer.observe(element));
        dotsFrames.forEach((element) => observer.observe(element));
        introPictures.forEach((element) => observer.observe(element));
        topComponents.forEach((element) => observer.observe(element));
    }, []); // Empty dependency array means this effect runs once on mount



    return (
        <div className='normal-text'>Fonts made from <a className='link' href="http://www.onlinewebfonts.com">Web Fonts</a> is licensed by CC BY 4.0</div>

       
    );
};

export default AddAnimation;