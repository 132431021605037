import React from 'react';

const MediumText = ({ text }) => {
    const formattedText = text.split('\n').map((line, index, array) => (
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ));
 
    return (
        <div>
            <p className="medium-headline highlighted-text">{formattedText}</p>
        </div>
    );
};

export default MediumText;