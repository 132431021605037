import React from 'react';

const PictureTextBox = ({ picture, headline, text }) => {
    const headlineStyle = {
        minHeight: '1.82em'       
    }


    return (
        <div className="picture-textbox">
            <img src={picture} alt="N/A" className="round-picture"/>
            <p className="small-headline" style={headlineStyle}>{headline}</p>
            <p className="normal-text">{text}</p>
        </div>
    );
};

export default PictureTextBox;