import React from 'react';
import './App.css';
import './flexfix.css';
import './breathing.css';

import SmallerTextBox from './smallerTextBox';
import BiggerTextBox from './biggerTextBox';
import PictureTextBox from './pictureTextBox';
import StandAlonePicture from './standAlonePicture';
import CustomerBox from './customerBox';
import BiggerText from './biggerText';
import MediumText from './mediumText';

import PictureStamp from './pictureStamp';
import DotsFrame from './dotsFrame';
import AddAnimation from './addAnimation';

function customers() {
  return (
    <><
      div className='left-headline'>
        <MediumText text={"From our users"} />
      </div>
      <div className='components-row'>
        <CustomerBox picture={'scatter-light.png'} headline={'Aaliyah Igwe'} text={"I had a fantastic experience working with CYBERMINDNET on developing my website. Their team was highly professional, attentive to detail, and delivered exceptional results."} />
        <CustomerBox picture={'scientist.png'} headline={'Francois Mercer'} text={"One of the standout aspects of their service was their communication. Throughout the project, their team kept us informed of progress, promptly addressed any concerns, and provided regular updates."} />
        <CustomerBox picture={'computer-world.png'} headline={'Yanis Petros'} text={"I recently worked with them for our cybersecurity needs, and their service was outstanding. Their team displayed expertise and ensured our systems were secure and compliant with industry standards."} />
      </div>
    </>
  );
}

function App() {
  return (
    <div className="App">
      <header className="header">
          <StandAlonePicture picture={'logo.svg'} format='header-logo' />
          <BiggerText text={'Delivering \n the future'} />
      </header>

      <body className="body">
        <div className='components-row'>
          <BiggerTextBox headline={'web development \n\n IT security \n\n data processing'} text={'CYBERMINDNET is a dynamic company specializing in web development, IT security, and data analysis. With a wealth of experience, our team consists of experts with many years of knowledge in both IT and marketing fields. Our mission is to provide cutting-edge solutions that empower businesses to thrive in the digital world. We believe in leveraging technology to create secure and innovative solutions tailored to our clients'}/>
          <StandAlonePicture picture={'keyboard-wave.png'} format={'intro-picture'}/>
        </div>  

        <div className='left-headline'>
          <MediumText text={"What's special about cybermindnet?"}/>
        </div>

        <div className='components-row'>
          <PictureTextBox picture={'scatter-light.png'} headline={"CREATIVITY"} text={"By combining our expertise with the latest industry trends and technologies, we are able to create engaging and effective solutions that help our clients stand out."}/>
          <PictureTextBox picture={'scientist.png'} headline={'PROFESSIONAL SOLUTION'} text={"Our team is highly skilled and dedicated to providing top-notch solutions in the field."}/>
          <PictureTextBox picture={'computer-world.png'} headline={'SECURITY'} text={"The service being provided ensures the utmost security and protection for your data. Safety is of paramount importance to us, and we prioritize it above all else."}/>        
        </div>

        <div className='left-headline'>
          <MediumText text={"why choose us?"}/>
        </div>

        <div className='stairs-components-row'>
          <SmallerTextBox headline={'Customized features'} alignment={'top'} text={"Tailored assignments crafted uniquely for you, no generic templates."}/>
          <SmallerTextBox headline={'\nVERSAILITY'} alignment={'center'} text={"From web development to cybersecurity, we've got you covered."}/>
          <SmallerTextBox headline={'\n24/7 SUPPORT'} alignment={'bottom'} text={"It is super-easy to contact us, fix bugs and develop new features."}/>
        </div> 
        
        <div className='center-headline'>
          <BiggerText text={'Our clients agree'}/>
        </div>

        <div className='stacked-components-row'>        
          <PictureStamp picture={'waves.svg'} headline={'*****'} text={'satisfaction rating'} />
          <PictureStamp picture={'vibrating-plane.svg'} headline={'enormous'} text={'increase in users'} />
          <PictureStamp picture={'broken-disc.svg'} headline={'great'} text={'daily feedback'} />  
        </div>

        <div className='center-headline'>
          <DotsFrame text={'Creating digital dreams, weaving data into success stories.'}/>
        </div>
      </body>
    
      <footer className="footer">

        <div className='left-headline'>
           <MediumText text={"Let's chat"}/>
        </div>

        <div className='table-column'>
          <div className="table-line">
            <p className="small-headline">Phone</p>
            <a href="tel:+420731808671" className="table-values-text">+420 731 808 671</a>
          </div>
          <div className="table-line">
            <p className="small-headline">Email</p>
            <a href="mailto:info@cybermindnet.eu" className="table-values-text">info@cybermindnet.eu</a>
          </div>
        </div>
        <AddAnimation />;
      </footer>


    </div>
  );
}

export default App;