import React from 'react';

const DotsFrame = ({ text }) => {
    return (
        <div className="dots-frame">
            <img src="dots-frame.svg" alt="N/A" className="dots-image" />            
            <p className="frame-text">{text}</p>
        </div>
    );
};

export default DotsFrame;