import React from 'react';

const StandAlonePicture = ({ picture, format }) => {
    return (
        <div>
            <img src={picture} alt="N/A" className={`${format}`}/>
        </div>
    );
};

export default StandAlonePicture;