import React from 'react';

const PictureStamp = ({ picture, headline, text }) => {
    return (
        <div className="picture-stamp">
            <img src={picture} alt="N/A" className="rectangular-picture" />
            <p className="medium-headline"><span className='highlighted-text'>{headline}</span></p>
            <p className="normal-text ">{text}</p>
        </div>
    );
};

export default PictureStamp;