import React from 'react';

const SmallerTextBox = ({ headline, text, alignment }) => {
    const alignmentStyle = (() => {
        switch (alignment) {
            case 'top':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'bottom':
                return 'flex-end';
            default:
                return 'auto'; // Default align-self value
        }
    })();

    const formattedHeadline = headline.split('\n').map((line, index, array) => (
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ));

    const formattedText = text.split('\n').map((line, index, array) => (
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ));

    return (
        <div className="smaller-textbox" style={{ alignSelf: alignmentStyle }}>
            <p className="small-headline">{formattedHeadline}</p>
            <p className="normal-text">{formattedText}</p>            
            <img src="3dots.svg" alt="N/A" className="dots3"/>
        </div>
    );
};

export default SmallerTextBox;