import React from 'react';

const BiggerTextBox = ({ headline, text }) => {
    const formattedHeadline = headline.split('\n').map((line, index, array) => (
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ));

    const formattedText = text.split('\n').map((line, index, array) => (
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    ));

    return (
        <div className="bigger-textbox">
            <p className="large-headline">{formattedHeadline}</p>
            <img src="3dots.svg" alt="N/A" className="dots3"/>
            <p className="normal-text">{formattedText}</p>
        </div>
    );
};

export default BiggerTextBox;